<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" href="/">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Testudy.io
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Testudy ✋
          </b-card-title>

          <b-alert :variant="variantForAccount" show v-if="msgToUser" >
            <div class="alert-body font-small-2">
              <p><small class="mr-50"><span class="font-weight-bold">{{ msgToUser }}</span></small></p>
            </div>
          </b-alert>

          <b-card-text class="mb-2" v-if="!msgToUser" >
            Please sign-in to your account and start the adventure
          </b-card-text>


          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="myemail@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              
              <b-form-group>
                
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>
                </div> 
              
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            
              <!-- checkbox -->
              <!-- <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group> -->

              <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="sitekey"
                @verify="login"
                @expired="onCaptchaExpired"
              />

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2 mb-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link> or
          </b-card-text>


          <div class="gsignin mb-1">
            <g-signin-button
              :params="googleSignInParams"
              @success="onGoogleSignInSuccess"
              @error="onGoogleSignInError"
            >
              <button class="btn btn-block btn-light">
                  <img
                    class="d-block-inline mr-1"
                    width="20"
                    height="20"
                    :src="require('@/assets/images/icons/google.png')"
                    alt="Google Signin"
                  >
                <span v-if="isInLoginingProcess">Loading...</span><span v-else>Sign in with Google</span>
              </button>
            </g-signin-button>



          </div>


          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              Need help?
            </div>
          </div>



          <!-- social buttons -->
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="https://www.facebook.com/profile.php?id=100086191706022" target="_blank" title="Facebook group">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="instagram" href="https://www.instagram.com/testudyio/" target="_blank" title="Instagram">
              <feather-icon icon="InstagramIcon" />
            </b-button>             
            <b-button variant="twitter" href="https://twitter.com/testudyio" target="_blank" title="Twitter">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="mailto:info@testudy.io?subject=Feedback from my.testudy.io" title="Email us">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="secondary" href="https://www.testudy.io/" target="_blank" title="www.testudy.io">
              <feather-icon icon="GlobeIcon" />
            </b-button>
            <b-button variant="warning" href="https://www.testudy.io/#contact" target="_blank" title="Contact us">
              <feather-icon icon="Edit3Icon" />
            </b-button> 
          </div>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
    <div id="loginspinner" v-if="isInLoginingProcess">
      <b-spinner
        class=""
        variant="primary"
        />
    </div>  

  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip, BSpinner
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { VueRecaptcha } from 'vue-recaptcha';


import Vue from 'vue'
import GSignInButton from 'vue-google-signin-button';
Vue.use(GSignInButton)


import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import axios from '@axios'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
    BAlert, VuexyLogo, ValidationProvider, ValidationObserver, VueRecaptcha, BSpinner
    },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      // password: 'admin',
      password: '',
      // userEmail: 'admin@demo.com',
      userEmail: '',
      //sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/pages/login.png'),

      // validation rules
      required,
      email,
      sitekey: process.env.VUE_APP_RECAPTCHA_TOKEN,
      captchaTryCount: 0,
      msgToUser: this.$route.params.msgToUser,
      msgIsDanger: false,
      // redirectUrl: $route.query.redirect   

      googleSignInParams: {
        client_id: '144292743784-0ffvetddaamkfb3uo0psoe75kcuf257c.apps.googleusercontent.com',
        scope: 'email', //otherway: trouble error: "popup_closed_by_user"
        plugin_name: 'Django Vue Auth', //otherway: trouble error: "popup_closed_by_user"
        // cookiepolicy: 'single_host_origin',
      },

      isInLoginingProcess: false


    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        this.sideImg = require('@/assets/images/pages/login.png')
        return this.sideImg
      }
      return this.sideImg
    },
    variantForAccount() {
      return this.msgIsDanger == true ? 'danger' : 'success';
    }    
  },
  methods: {
    login(recaptchaToken) {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isInLoginingProcess = true;	
          useJwt.login({
            email: this.userEmail,
            password: this.password,
            recaptcha: recaptchaToken,
          })
            .then(response => {

              //const { userData } = response.data
              const userData = response.data
              // useJwt.setToken(response.data.accessToken)
              // useJwt.setRefreshToken(response.data.refreshToken)
              // console.log(response.data)
              useJwt.setToken(response.data.access)
              useJwt.setRefreshToken(response.data.refresh)

              // console.log('userData, before:')
              // console.log(userData)

              // console.log('response.data, before:')
              // console.log(response.data)

              // testudy back give 'access', not 'accessToken'
              userData.accessToken = response.data.access
              delete response.data.access
              
              // testudy back give 'refresh', not 'refreshToken'
              userData.refreshToken = response.data.refresh               
              delete response.data.refresh  

              userData.name = response.data.name
              userData.email = response.data.email

              
              // console.log('after:')
              // console.log(userData)

              userData.ability = [
                  {
                    action: 'manage',
                    subject: 'all',
                  },
                ],


              localStorage.setItem('userData', JSON.stringify(userData))
              
              

              if (response.status === 429) {
                // console.log("response.status")  
                // console.log(response.status)  
                if (this.captchaTryCount <=2) {
                  this.$refs.recaptcha.execute(); 
                }
                this.captchaTryCount +=1 
              } else {
                if(this.$route.query.redirect) { // use query: { redirect: to.fullPath } from index.js
                  this.$router.push(this.$route.query.redirect)
                } else 
                  this.$router.push({ name: 'home' })
              }

              // this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              // this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
              //   .then(() => {
              //     this.$toast({
              //       component: ToastificationContent,
              //       position: 'top-right',
              //       props: {
              //         title: `Welcome ${userData.fullName || userData.username}`,
              //         icon: 'CoffeeIcon',
              //         variant: 'success',
              //         text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              //       },
              //     })
              //   })
            })
            .catch(error => {
              // this.$refs.loginForm.setErrors(error.response.data.error)
              // console.log('error.response.status')
              // console.log(error.response.status)
              // console.log("error.status")  
              // console.log(error.status)  
              this.msgIsDanger = true;
              if (error.status === 429) {
                if (this.captchaTryCount <=2) {
                  this.$refs.recaptcha.execute(); 
                }
                this.captchaTryCount +=1 
                // this.$refs.loginForm.setErrors(error.data); 
                this.msgToUser = error.data['email'];
              } else {
              // this.$refs.loginForm.setErrors(error.data); 
                this.msgToUser = error.data['email'];
              }

              // this.$refs.loginForm.setErrors(error.data);
              // this.$refs.loginForm.setErrors({'email':'asdasdasdasdasd',})
            })
            .finally(()=>{ 
              this.isInLoginingProcess = false;
            });			      

        }
      })
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    activateAccount(uid, token) {
      this.isInLoginingProcess = true;	
      useJwt.activateAccount(
        uid = uid, token = token
      )
        .then(response => {
          // const userData = response.data
          if (response.status === 200) {
            this.msgToUser='Your Account is activated! Now you can sign-in in the form bellow'; 
            this.msgIsDanger = false;
          } else {
            // this.$refs.loginForm.setErrors(response.data);
            this.msgToUser=response.data['email']
            this.msgIsDanger = true;
          }

        })
        .catch(error => {
          // this.$refs.loginForm.setErrors(error.data);
          this.msgToUser=error.data['email'];
          this.msgIsDanger = true;
        })
        .finally(()=>{ 
              this.isInLoginingProcess = false;
        });			      

    }, 
    

    onGoogleSignInSuccess (resp) {
      this.isInLoginingProcess = true;
      // console.log(resp);
      //const token = resp.Zi.access_token
      // const token = resp.zc.access_token
      // console.log(Object.keys(resp)[1])
      // console.log(Object.values(resp)[1])

      // const token = resp.Ic.access_token
      const token = Object.values(resp)[1].access_token
      useJwt.googleAuth(
          { access_token: token }          
        )
          .then(response => {
            const userData = response.data

            userData.accessToken = response.data.access_token
            delete response.data.access_token

            userData.refreshToken = response.data.refresh_token
            delete response.data.refresh_token

            useJwt.setToken(userData.accessToken)
            useJwt.setRefreshToken(userData.refreshToken)

            userData.id = response.data.user.id
            userData.name = response.data.user.name
            userData.email = response.data.user.email
            userData.credits = response.data.user.credits
            userData.is_premium = response.data.user.is_premium
            userData.subscription_period_end = response.data.user.subscription_period_end
            userData.stripe_customer_id = response.data.user.stripe_customer_id
            userData.questions_created = response.data.user.questions_created
            userData.symbols_free_alg = response.data.user.symbols_free_alg
            userData.symbols_paid_alg = response.data.user.symbols_paid_alg
            userData.char_limit_alg_free = response.data.user.char_limit_alg_free
            userData.char_limit_alg_paid = response.data.user.char_limit_alg_paid
            userData.subscription_name = response.data.user.subscription_name
            delete response.data.user
            userData.ability = [
                {
                  action: 'manage',
                  subject: 'all',
                },
              ],

            localStorage.setItem('userData', JSON.stringify(userData));

            if(this.$route.query.redirect) { // use query: { redirect: to.fullPath } from index.js
                  this.$router.push(this.$route.query.redirect)
                } else 
                  this.$router.push({ name: 'home' })
          })
          .catch(error => {
            // console.log('error:');
            // console.log(error);
            this.msgToUser=error.data['email'];
            this.msgIsDanger = true;
          })
          .finally(()=>{ 
            this.isInLoginingProcess = false;
          });			      

      // console.log('OH YEs')
      // console.log(token)
    },
    onGoogleSignInError (error) {
      let err = error['error']
      if (err == 'popup_closed_by_user') {
        err = 'You\'ve closed the authorization window. Please try again.'
      }
      this.msgToUser=err;
      this.msgIsDanger = true;

    },



    
    
  },



  mounted() {
    if ((this.$route.query.u) && (this.$route.query.t)) {
      this.msgToUser='Please, wait, your account is activating';
      this.activateAccount(this.$route.query.u, this.$route.query.t)
    }
    
  }
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

#loginspinner {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
  text-align: center;
}

#loginspinner .spinner-border {
  width: 10em;
  height: 10em;
  border-width: 0.8em;
}

</style>
